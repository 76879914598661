@use '../../variables' as v;

.MuiPagination-root {
  font-family: 'Source Sans Pro', 'sans-serif' !important;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  padding: 20px 10px;
  margin: 0 auto;

  .MuiPaginationItem-root {
    font-family: 'Source Sans Pro', 'sans-serif' !important;
    color: v.$charcoal;
    min-width: 0;

    &:hover,
    &:focus {
      background-color: transparent !important;
    }
    &.Mui-selected {
      background-color: transparent !important;
      font-weight: 700;
    }

    &.MuiPaginationItem-page {
      margin: 0;
      padding: 3px;
    }

    &.MuiPaginationItem-previousNext {
      margin: 0 10px;
    }

    &.MuiPaginationItem-firstLast {
      margin: 0;
    }
  }
}
