@use '../../../variables' as v;

.modal-content-action {
  width: 100%;
  .MuiFilledInput-underline {
    &::after {
      border-bottom: none;
    }
  }

  label {
    color: v.$xray;
    opacity: 0.5;
    top: -7px;

    &.Mui-focused {
      color: v.$xray;
      opacity: 0.5;
      top: -5px;
    }
  }
}

[aria-labelledby='action-reason'] li {
  margin-left: -8px;
}
