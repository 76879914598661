@use '../../variables' as v;

.MainPage {
  display: flex;
  height: 100vh;

  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    &.offline-border {
      border: 10px solid v.$visitRed !important;
    }

    &.online-border {
      border: 10px solid v.$forest !important;
    }
  }

  @media (min-width: 3840px) {
    zoom: 220%;
    height: 45.5vh;
  }
}
