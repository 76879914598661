@use '../../variables' as v;

.csv-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: v.$mainblue;

  h2 {
    margin: 0;
  }

  svg {
    cursor: pointer;
  }
}

.csv-upload-container {
  padding: 15px 25px;
  display: flex;
  flex-direction: column;

  .dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #707070;
    color: #707070;
    padding: 5px;
    min-height: 80px;
    cursor: default;
  }

  .upload-text {
    align-self: center;
    color: v.$marine;
    width: fit-content;
    cursor: pointer;
    margin-top: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid v.$mainorange;

    &:hover {
      color: v.$mainorange;
    }
  }
}

.loading-container {
  min-height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 20px 10px;
  gap: 15px;
  font-size: 1.5rem;
  color: v.$marine;

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  img {
    animation: loading 2s linear infinite;
    width: 55px;
    height: 55px;
  }
}

#csv-cancel-btn {
  background-color: v.$csvCancelRed;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
  padding: 5px 15px;
  margin-right: 10px;

  &:hover {
    background-color: #ffffff;
    border-color: v.$csvCancelRed;
    color: v.$csvCancelRed;
  }
}

.file-container {
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: 10px 10px 0 10px;

  .csv-file {
    display: flex;
    justify-content: center;
    align-items: center;
    color: v.$marine;
    gap: 10px;
    word-break: break-all;

    .file-icon {
      height: 30px;
      width: 20px;
    }
  }

  &.location-changes {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 0 0 0;

    p {
      margin: 0;
      color: v.$marine;
      font-weight: bold;

      span {
        color: v.$mainorange;
        margin: 0 5px;
      }
    }
  }
}

// .MuiButtonBase-root {
//   &.csv-cancel-btn {
//     text-transform: unset;
//     background-color: #F9585D;
//     border-bottom: none !important;
//     border: 1.5px solid #F9585D;
//     border-radius: 20px !important;
//     // padding-bottom: unset !important;
//     color: #FFFFFF !important;
//   }
// }

.status-modal-header {
  margin: 0;
  color: v.$mainblue;
  margin-bottom: 10px;
}

.status-modal-content {
  &.MuiFormControl-root {
    margin: 0 20px;

    .MuiRadio-root {
      padding: 5px;
      color: v.$mainorange;
    }

    .MuiFormControlLabel-label {
      color: v.$marine;
      font-weight: 600;
    }
  }

  &.MuiBox-root {
    text-align: center;
    margin-bottom: 20px;

    span {
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 4px;

      &.online {
        color: v.$forest;
      }

      &.offline {
        color: v.$visitRed;
      }
    }
  }
}
