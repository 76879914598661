@use '../../variables' as v;

.location-settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-weight: 500;
  font-size: 24px;
  font-family: 'Source Sans Pro', 'sans-serif';
  color: v.$marine;

  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableCell-root {
        font-family: 'Source Sans Pro', 'sans-serif' !important;
        font-size: 18px;
        font-weight: 700 !important;
        color: v.$marine;
        padding: 0px 10px;
        border-color: v.$marine !important;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          font-family: 'Source Sans Pro', 'sans-serif' !important;
          padding: 15px 10px;
          color: v.$marine !important;
          font-size: 1.1rem;

          &.status-td {
            font-weight: 700;
            text-transform: uppercase;
            color: v.$visitRed !important;
            &.online {
              color: v.$forest !important;
            }
          }
        }
      }
    }
  }

  .ellipsis-icon {
    height: 8px;

    &.hovered {
      cursor: pointer;

      path {
        fill: v.$mainorange;
      }
    }
  }
}
