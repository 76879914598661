@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */

.rows-per-page {
  text-align: center;
  color: v.$xray;
  margin-top: -3px;
  p {
    margin: 0;
  }
  span.MuiSwitch-root {
    margin-top: -5px;
    height: 40px;
  }

  span {
    span.MuiSwitch-track {
      background-color: v.$mainorange;
      opacity: 1;
    }
  }
}
