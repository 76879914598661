$supplies-splint: #7b4b22;
$uadip: #f5c452;
$lab: #f9585d;
$urgentcare: #f99858;
$meds-bp: #ffffff;
$actions: #5e2392;
$xray: #000000;
$leaf: #789d4a;
$forest: #006c67;
$plum: #5d285f;
$mainblue: #236192;
$mainorange: #ed8b00;
$marine: #002855;
$sky: #80a6ce;
$charcoal: #54585a;
$black: #000000;
$visitRed: #cb3737;
$visitYellow: #f1e103;
$csvCancelRed: #f9585d;
$disabledBorder: #bbbbbb;
$disabledText: #919292;
$disabledBackground: #c8cacc;
