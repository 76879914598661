@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */
.location {
  cursor: pointer;

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg.location-pin,
  svg.return-arrow {
    margin-top: -25px;
    width: 30px;
  }
}
