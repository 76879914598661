@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */

.header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-top: 10px;

  &.grid {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    grid-template-rows: max-content;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .logo-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: fit-content;

    h1 {
      padding: 10px;
      padding-left: 5px;
      font-size: 32px;
      font-weight: 700;
      color: v.$mainblue;
      line-height: normal;
      margin: 0;
      max-width: 35vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-weight: 700;
      font-size: 32px;
      color: v.$mainorange;
    }

    svg {
      height: 40px;
    }
  }

  .clock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .time-title {
      color: v.$mainorange;
    }

    .time {
      font-weight: bold;
      color: v.$marine;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .colon {
        animation: blink 2s step-start infinite;
        font-weight: bold;
        margin: 0 2px;

        @keyframes blink {
          50% {
            opacity: 0;
          }
        }
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .status {
      text-align: center;
      margin-right: 20px;

      .current-status {
        margin-bottom: 5px;
        span {
          margin: 0 5px;
          text-transform: uppercase;
          color: v.$visitRed;
          font-weight: 700;
          &.online {
            color: v.$forest;
          }
        }
      }

      .set-status {
        &.MuiButton-root {
          font-size: 1rem;
          width: 125px;
          border: 1px solid v.$mainorange;
          border-radius: 40px;
          text-transform: none;
          background-color: v.$mainorange;
          box-shadow: none;
          padding: 2px;
          &:hover {
            background-color: white;
            color: v.$mainorange;
            border-color: v.$mainorange;
          }

          &:disabled {
            border-color: v.$disabledBorder;
            color: v.$disabledText;
            background-color: v.$disabledBackground;
          }
        }
      }
    }

    .dropdown-controls {
      display: flex;
      align-items: center;

      .MuiInputBase-input {
        padding: 10px;
        border-color: v.$mainorange;
        border-radius: 2px;
      }

      .MuiInputLabel-root {
        color: v.$mainorange;

        &:hover {
          color: v.$mainorange;
        }

        &.Mui-focused {
          color: v.$mainorange;
        }
      }

      .MuiOutlinedInput-root {
        max-width: 280px;

        svg.svg-inline--fa.fa-angle-down {
          font-size: 1rem;
          position: absolute;
          right: 15px;
          top: calc(50% - 0.5em);
          pointer-events: none;
          color: rgba(0, 0, 0, 0.54);
          transform: rotate(0deg);

          &.rotate-icon {
            transform: rotate(180deg);
          }
        }

        fieldset {
          border-color: v.$mainorange;
          border-radius: 2px;
        }

        &:hover fieldset {
          border-color: v.$mainorange;
        }

        &.Mui-focused fieldset {
          border-color: v.$mainorange;
          border-width: thin;
        }

        .MuiSelect-select {
          &.colorRed {
            color: red;
          }
        }
      }
    }

    .settings-search {
      margin-right: 10px;

      .MuiOutlinedInput-root {
        width: 500px;
        max-width: 500px;
      }

      .MuiInputBase-input,
      .MuiOutlinedInput-root fieldset,
      &:hover fieldset,
      &.Mui-focused fieldset {
        border-color: v.$mainblue;
      }
    }

    .MuiIconButton-root {
      // width: 45px;
      border-color: v.$mainblue;
      background-color: v.$mainblue;
      border-radius: 2px;
      margin-right: 10px;

      &:hover {
        background-color: v.$marine;
      }

      svg {
        fill: white;
      }
    }

    #whiteboard-pagination {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .pagination-title {
        color: v.$mainorange;
      }

      .page {
        font-size: 32px;
        color: v.$mainblue;
        font-weight: bold;
      }
    }
  }
}
