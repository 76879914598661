@use '../../variables' as v;

.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Source Sans Pro', 'sans-serif';
  color: v.$marine;
  position: relative;

  h4 {
    margin-top: 3px;
    margin-left: 0;
    margin-bottom: 5px;
  }
  .close-icon {
    position: absolute;
    font-size: 26px;
    margin: 0;
    margin-top: -3px;
    font-weight: 900;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}
.search-icon {
  margin-left: -2px;
}

.search-row {
  display: flex;
  align-items: flex-end;

  .MuiTextField-root {
    width: 80%;
    margin-left: 5px;
  }

  input {
    border: none;
    &::placeholder {
      font-size: 12px;
      color: v.$charcoal;
    }
  }

  .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
    border: none;
  }

  .MuiInputBase-formControl:hover {
    border: none;
  }
}
.location-row {
  margin-top: 8px;
  overflow-y: auto;
  height: 250px;
  scrollbar-color: v.$mainorange rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;

  h4,
  button {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  button {
    margin-left: 10px;
    color: v.$marine;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;

    background: none !important;
    border: none;
    padding: 0 !important;

    width: 100%;
    text-align: left;
    text-decoration: none;
  }

  h4 {
    color: v.$mainorange;
  }
}

.location-pill-container {
  margin-top: 5px;
}
