@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */
.profile {
  cursor: pointer;

  > div {
    display: flex;
    justify-content: center;
  }

  .con-img {
    button {
      background: none !important;
      border: none;
      padding: 0 !important;

      width: 100%;
      text-align: left;
      text-decoration: none;
      font-weight: bold;

      img {
        border: 2px solid v.$mainorange;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  img {
    width: 40px;
    text-align: center;
  }
}
