@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */
.side-bar {
  background: v.$mainblue;
  padding: 30px 5px;
  color: v.$meds-bp;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: fit-content;
  max-width: 150px;
  width: 50px;
}
