@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */

.pill-button {
  color: v.$meds-bp;
  border: 1.5px solid v.$meds-bp;
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Source Sans Pro', 'sans-serif';

  &:hover,
  &:focus {
  }
}
