@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */

.action-button {
  background: v.$mainorange;
  color: v.$meds-bp;
  border: 1.5px solid v.$mainorange;
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;
  font-family: 'Source Sans Pro', 'sans-serif';

  &:hover,
  &:focus {
    background: v.$meds-bp;
    color: v.$mainorange;
    border: 1.5px solid v.$mainorange;
  }
}
