@use '../../variables' as v;
/* sass intellisense extension for variable suggestion vs-code: SCSS IntelliSense */

.main-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-weight: 500;
  font-size: 24px;
  font-family: 'Source Sans Pro', 'sans-serif';

  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableCell-root {
        font-family: 'Source Sans Pro', 'sans-serif' !important;
        font-size: 18px;
        font-weight: 700 !important;
        color: v.$marine;
        padding: 0 10px;
        border-color: v.$marine !important;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &.up-next {
          background-color: rgba(v.$mainorange, 0.3);
        }

        &.triage:not(:first-child) {
          .MuiTableCell-root {
            &.triage {
              border-top: 0.5px solid v.$lab;
            }
          }
        }

        .MuiTableCell-root {
          &.triage {
            border-top: 2px solid v.$lab;
            border-bottom: 2px solid v.$lab;

            &:first-child {
              border-left: 2px solid v.$lab;
            }

            &:last-child {
              border-right: 2px solid v.$lab;
            }
          }

          &.large-initials {
            font-weight: bold;
            font-size: 1em !important;
          }

          .up-next,
          .triage {
            color: v.$marine;
            font-weight: 700;
          }

          .status-actionable {
            font-weight: bold;
          }

          .triage {
            text-transform: uppercase;
          }
        }
      }

      .action-timmer-column,
      .room-column {
        padding-right: 0 !important;
        position: relative;

        .action-blur {
          animation-name: blurbox;
          animation-duration: 3s;
          filter: blur(4px);
        }

        .action-add-trigger {
          width: 60px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          align-self: stretch;
        }

        .action-patient-trigger {
          right: unset;
          left: 0;
          width: 10px;
        }

        .add-circle {
          transform: scale(1.5);
          color: v.$marine;
        }
        .delete-circle {
          transform: scale(1.3);
          color: v.$lab;
        }

        .add-circle-con,
        .delete-circle-con {
          position: relative;
          width: fit-content;
          height: fit-content;
          cursor: pointer;
        }

        .add-circle-con::before,
        .delete-circle-con::before {
          position: absolute;
          content: '';
          width: 20px;
          height: 20px;
          background-color: v.$meds-bp;
          top: 0;
          left: 2px;
          bottom: 2px;
          right: 2px;
          border-radius: 50%;
        }

        .action-add-trigger-expand {
          width: 95%;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        @keyframes blurbox {
          0% {
            filter: blur(0.5px);
          }
          50% {
            filter: blur(2px);
          }
          100% {
            filter: blur(4px);
          }
        }
        .align-center {
          display: flex;
          align-items: center;
        }
      }

      .MuiTableCell-root {
        font-family: 'Source Sans Pro', 'sans-serif' !important;
        padding: 5px 10px;
        color: v.$marine;
        letter-spacing: 0;

        &.rowsPerPage-10 {
          padding: 15px 10px;
          font-size: 16px;

          .order {
            > div:not(:first-child) {
              font-size: 10px;
            }
          }

          .timer {
            .subscript {
              font-size: 10px;
            }
          }

          .edit-icon {
            font-size: 15px;
            cursor: pointer;
          }
        }

        .edit-icon {
          font-size: 12px;
          margin-right: 3px;
          cursor: pointer;
        }

        .visit-type-col {
          display: flex;
          flex-direction: column;
          width: fit-content;

          .provider {
            font-size: 14px;
            text-align: left;
            padding: 4px 8px 4px 8px;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        .pill {
          border: 1px solid v.$marine;
          text-align: center;
          border-radius: 36px;
          padding: 0 15px;

          &.visit-type {
            color: white;
            width: fit-content;
            border-color: v.$leaf;
            background-color: v.$leaf;

            &.triage {
              display: none;
            }

            &.physical,
            &.physical-non-dot,
            &.recheck-physical-dot,
            &.physical-dot,
            &.primary-care,
            &.pcp-exam {
              border-color: v.$mainblue;
              background-color: v.$mainblue;
            }

            &.urgent-care,
            &.uc-physical-therapy,
            &.health-coach,
            &.counseling {
              border-color: v.$urgentcare;
              background-color: v.$urgentcare;
            }

            &.occupational-therapy,
            &.physical-therapy {
              border-color: v.$actions;
              background-color: v.$actions;
            }

            &.specialist-recheck,
            &.injury-recheck,
            &.non-injury-recheck,
            &.recheck {
              border-color: v.$black;
              background-color: v.$black;
            }

            &.new-injury {
              border-color: v.$visitRed;
              background-color: v.$visitRed;
            }

            &.drug-screen {
              border-color: v.$visitYellow;
              background-color: v.$visitYellow;
              color: v.$black;
            }
          }

          &.orders {
            display: inline-block;
            margin-right: 5px;

            &.completed {
              background-color: v.$leaf;
              border-color: v.$leaf;
              color: white;
            }
          }

          &.actions {
            border-color: v.$actions;
            background-color: v.$actions;
            color: white;

            &:last-of-type {
              margin-right: 2px !important;
            }
          }
        }

        .order {
          position: relative;
          z-index: 0;
          min-width: fit-content;
          cursor: default;

          &.isWorkStation {
            cursor: pointer;
          }

          > div:not(:first-child) {
            z-index: 1;
            position: absolute;
            top: 18px;
            height: 10px;
            width: 10px;
            border: 1px solid v.$marine;
            border-radius: 50%;
            font-size: 8px;
            line-height: 10px;
            padding: 2px;
            text-align: center;
            background-color: white;
            color: v.$marine;
            font-weight: 700;
            &.subscript-0 {
              left: 0;
            }
            &.subscript-1 {
              left: 13px;
            }
            &.subscript-2 {
              left: 25px;
            }
            &.subscript-3 {
              left: 37px;
            }
            &.subscript-4 {
              left: 49px;
            }

            &.complete {
              border-color: white;
              color: white;
              background-color: v.$leaf;
            }
          }
        }

        .timer {
          position: relative;
          z-index: 0;
          .subscript {
            z-index: 1;
            position: absolute;
            top: 18px;
            right: 8px;
            height: 10px;
            width: 10px;
            border: 1px solid v.$mainorange;
            border-radius: 50%;
            font-size: 8px;
            line-height: 10px;
            padding: 2px;
            text-align: center;
            background-color: white;
            color: v.$mainorange;
            font-weight: 600;
          }
        }

        .pulse-timer {
          transform: scale(1.1);
          .timer-watch {
            border-radius: 50%;
            background-clip: padding-box;
            position: relative;
            animation-name: grow;
            animation-timing-function: ease-in-out;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
        }
        @keyframes grow {
          from {
            transform: scale(0.9);
          }
          to {
            transform: scale(1.1);
          }
        }

        .add-clock-con::before {
          background-color: v.$meds-bp;
          position: absolute;
          border: 4px solid v.$mainorange;
          opacity: 0.5;
          content: '';
          width: 25px;
          height: 25px;
          top: -1.5px;
          left: -1.5px;
          bottom: 0;
          z-index: 0;
          right: 0;
          border-radius: 50%;
          animation-name: pulse;
          animation-timing-function: ease-in-out;
          animation-duration: 1s;
          animation-iteration-count: infinite;
        }

        @keyframes pulse {
          0% {
            background-color: v.$meds-bp;
            border: 4px solid v.$mainorange;
            opacity: 0;
          }
          100% {
            background-color: v.$meds-bp;
            position: absolute;
            border: 5px solid v.$mainorange;
            opacity: 0.5;
            content: '';
            width: 25px;
            height: 25px;
            top: -1.5px;
            left: -1.5px;
            bottom: 0;
            z-index: 0;
            right: 0;
            border-radius: 50%;
          }
        }
      }
    }
  }

  p {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
}

.css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  background: transparent !important;
}

.css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before {
  border-bottom: 0 !important;
}

.modal-content-action {
  .MuiFilledInput-underline {
    &::after {
      border-bottom: none;
    }
  }

  label {
    color: v.$xray;
    opacity: 0.5;
    top: -8px;
  }

  .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select {
    padding: 12px 12px 5px 10px;
  }
}

.action-button-container {
  display: flex;
  width: 100%;
  justify-content: right;

  &.tooltip {
    justify-content: center;

    .action-button {
      font-size: 12px;
    }
  }

  &.order-tooltip {
    justify-content: flex-end;
    gap: 5px;

    #remove-order-btn {
      background: v.$meds-bp;
      color: v.$marine;
      border: 1.5px solid v.$mainorange;
      border-radius: 20px;
      padding: 5px 15px;
      cursor: pointer;
      font-family: 'Source Sans Pro', 'sans-serif';

      &:hover,
      &:focus {
        background: v.$mainorange;
        color: v.$meds-bp;
        border: 1.5px solid v.$mainorange;
      }
    }

    .action-button {
      font-size: 12px;
    }
  }

  button {
    border-width: 2px;
  }

  .MuiButtonBase-root {
    margin-right: 15px;
    border-radius: 0;
    border-bottom: 3px solid v.$mainorange;
    color: v.$marine;
    padding-bottom: 3px;

    &:hover {
      background-color: transparent;
      color: v.$mainorange;
    }
  }
}

.m-title-container {
  display: flex;
  align-items: flex-end;

  button {
    margin-right: 10px;
    margin-top: 0;
    color: v.$sky;
    font-size: 14px;
    margin-bottom: 0;
    cursor: pointer;

    background: none !important;
    border: none;
    padding: 0 !important;

    // width: 100%;
    text-align: left;
    text-decoration: none;
    font-weight: bold;
  }
  .m-title-active {
    color: v.$marine;
    font-size: 20px;
  }
}

.action-col {
  display: flex;
  justify-content: space-between;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: v.$marine;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
}

.modal-main-content {
  display: flex;
  #action-timer,
  #order-description {
    top: -10px;
    padding-top: 2px;
  }

  label#action-timer,
  label#order-description {
    color: v.$xray;
    opacity: 0.5;

    &.Mui-focused {
      color: v.$marine;
      opacity: 1;
      top: -9px;
    }
  }

  .timer-input,
  .order-description-input {
    input {
      padding: 15px 10px 7px 12px;
      font-size: 14px;
    }

    fieldset {
      height: 100%;
      border: none;
    }
  }

  .room-input,
  .mss-input,
  .patient-input {
    input {
      padding: 9.5px;
      font-size: 1rem;
    }

    fieldset {
      height: 100%;
      border: none;
    }

    label {
      visibility: hidden;
    }
  }

  .inline-inputs {
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    &:not(:nth-child(3)) {
      margin-bottom: 10px;
    }

    .MuiOutlinedInput-root {
      border: 1px solid v.$sky;
      border-radius: 0;
      margin: 5px 0;
      width: 100%;
    }

    .Mui-error {
      border-color: #d32f2f;
    }

    .patient-dob {
      label {
        color: #a2a2a2;
      }

      &.hide-label {
        label {
          visibility: hidden;
        }
      }

      input {
        padding: 9px;
        font-size: 1rem;
      }

      fieldset {
        height: 100%;
        border: none;
      }

      label {
        &.Mui-focused,
        &.Mui-error {
          visibility: hidden;
        }
      }
    }
  }
}

.timer-tooltip {
  .close-icon {
    position: absolute;
    font-size: 20px;
    margin: 0;
    font-weight: 900;
    top: 0;
    color: v.$mainorange;
    right: -3px;
    cursor: pointer;
  }
}

.action-container {
  position: relative;
  cursor: default;
  margin-right: 5px;

  .close-icon-action {
    position: absolute;
    top: -7px;
    left: -5px;
    font-size: 15px;
    border: 1px solid v.$actions;
    padding: 0;
    color: v.$mainorange;
    border-radius: 50%;
    background-color: v.$meds-bp;
    cursor: pointer;
  }
}

.flag-icon {
  width: 50px;
  margin-right: 18px;
  svg {
    transform: scale(0.42);
    cursor: pointer;
  }
}

.view-data-btn {
  &.MuiButton-root {
    position: absolute;
    bottom: 0;
    margin: 0 0 12px 20px;
    left: 0;
    border: 1px solid v.$mainorange;
    border-radius: 40px;
    padding: 2px 20px;
    color: v.$marine;
    text-transform: unset;
    font-size: 1rem;

    span {
      margin: 0 3px;
      font-weight: 700;
      &.online {
        color: v.$forest;
      }

      &.offline {
        color: v.$visitRed;
      }
    }

    &:hover {
      background-color: v.$mainorange;
      color: white;
      span {
        color: white;
      }
    }
  }
}

.triage-tab {
  position: absolute;
  bottom: 0;
  padding: 12px 0px;
  right: 0;

  .triage-add-trigger {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .add-triage-btn {
      cursor: pointer;
      color: white;
      background-color: v.$lab;
      border: 1px solid v.$lab;
      text-align: center;
      border-radius: 36px;
      padding: 3px 15px;
      margin: 12px 5px;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        background-color: white;
        color: v.$lab;
      }
    }

    .add-patient-btn {
      cursor: pointer;
      color: white;
      background-color: v.$mainorange;
      border: 1px solid v.$mainorange;
      text-align: center;
      border-radius: 36px;
      padding: 3px 15px;
      margin: 12px 5px;
      font-size: 14px;
      font-weight: 600;
      width: fit-content;

      &:hover {
        background-color: white;
        color: v.$mainorange;
      }
    }
  }
}
